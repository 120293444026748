import React from 'react';
import { Divider, Header } from 'semantic-ui-react';

import PageWrapper from '../components/PageWrapper';

const Contact: React.FC = () => {
  return (
    <PageWrapper active="contact">
      <Header size="huge" content="Contact" textAlign="center" />
      <Divider section={true} horizontal={true}>
        <Header size="large" content="e-mail" />
      </Divider>
      <p style={{ textAlign: 'center' }}>sono888.gamestudio＠gmail.com</p>
      <Divider section={true} horizontal={true}>
        <Header size="large" content="twitter" />
      </Divider>
      <p style={{ textAlign: 'center' }}>
        <a href="https://twitter.com/_sono_8_" target="blank">
          @_sono_8_
        </a>
      </p>
    </PageWrapper>
  );
};

export default Contact;
