import React from 'react';
import { useHistory } from 'react-router-dom';
import { firebaseAuth } from '../firebase/index';

const Logout: React.FC = () => {
  const history = useHistory();

  firebaseAuth.onAuthStateChanged((user) => {
    if (user) {
      firebaseAuth.signOut().then(() => {
        history.push('/');
      });
    } else {
      history.push('/');
    }
  });

  return null;
};

export default Logout;
