import React from 'react';
import { Divider, Header, List } from 'semantic-ui-react';
import PageWrapper from '../components/PageWrapper';

const ProductHome: React.FC = () => {
  return (
    <PageWrapper active="product">
      <Header size="huge" content="Product" textAlign="center" />
      <Divider section={true} horizontal={true}>
        <Header size="large" content="Games" />
      </Divider>
      <List bulleted={true} style={{ padding: '1em 2em' }}>
        <List.Item
          as="a"
          target="_blank"
          href="https://github.com/sono8stream/FesGame18"
        >
          すたばと！ (Tokyo Game Show 2018 Indie A部門出展) (Unity C#)
        </List.Item>
        <List.Item
          as="a"
          target="_blank"
          href="https://github.com/sono8stream/scenarioSystem"
        >
          Simple ADV system for Unity (Unityエディタ拡張)
        </List.Item>
        <List.Item
          as="a"
          target="_blank"
          href="https://github.com/sono8stream/SummerStudentGame"
        >
          ニンブパラク (Unity C#)
        </List.Item>
        <List.Item
          as="a"
          target="_blank"
          href="https://github.com/sono8stream/theKernelofSkyscrapers"
        >
          摩天楼の心臓 (Tokyo Sand Box 2017，デジゲー博2016出展) (Unity C#)
        </List.Item>
        <List.Item
          as="a"
          target="_blank"
          href="https://github.com/sono8stream/Kawaz-Jump"
        >
          かわずくんジャンプ (Unity C#)
        </List.Item>
        <List.Item
          as="a"
          target="_blank"
          href="https://github.com/sono8stream/Planet-Pinball"
        >
          Planet Pinball (Unity C#)
        </List.Item>
        <List.Item
          as="a"
          target="_blank"
          href="https://github.com/sono8stream/WhereCat"
        >
          Where Cat? (Unity C#)
        </List.Item>
        <List.Item
          as="a"
          target="_blank"
          href="https://github.com/sono8stream/Lv.001"
        >
          Lv.001 (Unity C#)
        </List.Item>
        <List.Item
          as="a"
          target="_blank"
          href="https://github.com/sono8stream/Ika_puzzle_1on1"
        >
          イカP 1on1 ~対戦！パズルめいろ~ (XNA Game Library)
        </List.Item>
        <List.Item>Some other unpublished games...</List.Item>
      </List>
      <Divider section={true} horizontal={true}>
        <Header size="large" content="Web" />
      </Divider>
      <List bulleted={true} style={{ padding: '1em 2em' }}>
        <List.Item>This web site (React + Firebase)</List.Item>
        <List.Item
          as="a"
          target="_blank"
          href="https://codeforces-anytime.sonoapp.page"
        >
          Codeforces Anytime (React + Firebase)
        </List.Item>
        <List.Item as="a" target="_blank" href="https://game-db.sonoapp.page">
          GameDB! (React + Firebase)
        </List.Item>
        <List.Item
          as="a"
          target="_blank"
          href="https://gamma-creators.firebaseapp.com/blogs"
        >
          Kawaz Student official blog (Next.js + Firebase)
        </List.Item>
      </List>
      <Divider section={true} horizontal={true}>
        <Header size="large" content="Mobile App" />
      </Divider>
      <List bulleted={true} style={{ padding: '1em 2em' }} />
      <Divider section={true} horizontal={true}>
        <Header size="large" content="Other" />
      </Divider>
      <List bulleted={true} style={{ padding: '1em 2em' }}>
        <List.Item
          as="a"
          target="_blank"
          href="https://github.com/sono8stream/AtPractice/tree/master/AtTest/Library"
        >
          Algorithm Library(C#)
        </List.Item>
        <List.Item as="a" target="_blank" href="https://github.com/sono8stream">
          Some other codes on Github
        </List.Item>
      </List>
    </PageWrapper>
  );
};

export default ProductHome;
