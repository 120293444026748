import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps, useParams } from 'react-router-dom';
import { Button, Header, Loader, Modal } from 'semantic-ui-react';
import PageWrapper from '../components/PageWrapper';
import { firebaseAuth, fireStore } from '../firebase';

import PostShow from '../components/PostShow';

const DiaryShow: React.FC<RouteComponentProps<{ id: string }>> = (props) => {
  const urlParams = useParams<{ id: string }>();

  const [authed, setAuthed] = useState(false);
  const [postData, setPostData] = useState<any>(null);
  const [loaded, setLoaded] = useState(false);
  const [onDelete, setOnDelete] = useState(false);

  useEffect(() => {
    firebaseAuth.onAuthStateChanged((user) => {
      const id = urlParams.id;
      fireStore
        .collection('diary')
        .doc(id)
        .get()
        .then((documentSnapshot) => {
          const data = documentSnapshot.data();
          if (data) {
            setPostData(data);
          }
          if (user) {
            setAuthed(true);
          }
          setLoaded(true);
        })
        .catch(() => {
          setLoaded(true);
        });
    });
  }, [urlParams]);

  return (
    <PageWrapper active="diary">
      {(() => {
        if (authed) {
          return (
            <div>
              <Button
                basic={true}
                color="blue"
                content="編集"
                as={Link}
                to={`${props.match.params.id}/edit`}
              />
              <Button
                basic={true}
                color="red"
                content="削除"
                onClick={() => setOnDelete(true)}
              />

              <Modal
                basic={true}
                onClose={() => setOnDelete(false)}
                onOpen={() => setOnDelete(true)}
                open={onDelete}
                size="small"
              >
                <Header>この記事を削除します</Header>
                <Modal.Content>
                  <p>記事名 : {postData.title || ''}</p>
                  <p>よろしいですか？</p>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    color="green"
                    inverted={true}
                    onClick={() => setOnDelete(false)}
                  >
                    キャンセル
                  </Button>
                  <Button
                    color="red"
                    content="削除"
                    inverted={true}
                    as={Link}
                    to={`${props.match.params.id}/delete`}
                  >
                    削除
                  </Button>
                </Modal.Actions>
              </Modal>
            </div>
          );
        }
      })()}
      {(() => {
        if (loaded) {
          if (postData) {
            return (
              <PostShow
                accessibility={postData.accessibility}
                title={postData.title}
                content={postData.content}
                date={postData.date}
                updateDate={postData.updateDate}
                categories={postData.categories}
              />
            );
          } else {
            return (
              <Header
                size="medium"
                content="ページが見つかりませんでした"
                textAlign="center"
              />
            );
          }
        } else {
          return (
            <Loader active={true} inline="centered">
              Loading
            </Loader>
          );
        }
      })()}
    </PageWrapper>
  );
};

export default DiaryShow;
