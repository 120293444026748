import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router';

interface Props {
  children: any;
}

const ScrollToTop: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    const { pathname } = location;
    ReactGA.set({ page: pathname });
    ReactGA.pageview(pathname);
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return children;
};

export default ScrollToTop;
