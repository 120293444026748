import React from 'react';
import { Button, Card, Header, Image, Modal } from 'semantic-ui-react';
import { firebaseStorage, fireStore } from '../firebase';

interface Props {
  onCloseModal: (selectedUrls: string[]) => void;
}

interface State {
  isOpen: boolean;
  imageNames: string[];
  imageUrls: string[];
  selected: boolean[];
}

class ImageManager extends React.Component<Props, State> {
  private uploadInputRef: React.RefObject<HTMLInputElement>;

  private storageRef: firebase.storage.Reference;
  private imageNamesRef: firebase.firestore.DocumentReference;

  private unsubscribe: () => void;
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      imageNames: [],
      imageUrls: [],
      selected: [],
    };

    this.uploadInputRef = React.createRef();

    this.storageRef = firebaseStorage.ref('articles');
    this.imageNamesRef = fireStore.collection('images').doc('articles');

    this.unsubscribe = this.imageNamesRef.onSnapshot((doc) => {
      const names = doc.data()!.names;
      const urls: string[] = [];
      let selected: boolean[] = [];
      for (const name of names) {
        this.storageRef
          .child(name)
          .getDownloadURL()
          .then((url) => {
            urls.push(url);
          });
      }
      selected = Array(urls.length);
      selected.fill(false);

      this.setState({
        isOpen: false,
        imageNames: names,
        imageUrls: urls,
        selected,
      });
    });
  }

  public componentWillUnmount() {
    this.unsubscribe();
  }

  public async switchModal(turnOn: boolean) {
    this.setState({ isOpen: turnOn });
  }

  public getDateString() {
    const date = new Date();
    let str = date.toLocaleDateString() + '-' + date.toLocaleTimeString();
    str = str.replace(/\//g, '-');
    return str;
  }

  public getExtension(fileName: string) {
    let str = '';
    for (let i = fileName.length - 1; i >= 0; i--) {
      str = fileName[i] + str;

      if (fileName[i] === '.') {
        break;
      }
    }
    return str;
  }

  public async uploadImage(e: any) {
    const imageFile = e.target.files[0];
    const name = this.getDateString() + this.getExtension(imageFile.name);

    const uploadRef = this.storageRef.child(name);
    await uploadRef.put(imageFile);
    await this.imageNamesRef.update({
      names: this.state.imageNames.concat([name]),
    });
  }

  public switchSelected(index: number) {
    const nextSelected = [...this.state.selected];
    nextSelected[index] = !nextSelected[index];
    this.setState({ selected: nextSelected });
  }

  public async returnSelection() {
    const urls = [];
    for (let i = 0; i < this.state.selected.length; i++) {
      if (this.state.selected[i]) {
        urls.push(this.state.imageUrls[i]);
      }
    }
    await this.switchModal(false);
    await this.setState({ selected: Array(urls.length).fill(false) });

    this.props.onCloseModal(urls);
  }

  public render() {
    return (
      <div>
        <Button
          basic={true}
          color="blue"
          content="画像を追加"
          onClick={() => this.switchModal(true)}
        />

        <Modal open={this.state.isOpen} onClose={() => this.switchModal(false)}>
          <Header content="画像を選ぶ" />
          <Modal.Content scrolling={true}>
            <Card.Group itemsPerRow={3}>
              {this.state.imageUrls.map((url, index) => {
                return (
                  <Card
                    link={true}
                    key={index}
                    color={this.state.selected[index] ? 'red' : 'blue'}
                    onClick={() => {
                      this.switchSelected(index);
                    }}
                  >
                    <Image src={url} key={index} />
                  </Card>
                );
              })}
            </Card.Group>
          </Modal.Content>
          <Modal.Actions>
            <Button content="閉じる" onClick={() => this.switchModal(false)} />
            <input
              type="file"
              style={{ display: 'none' }}
              onChange={(e) => this.uploadImage(e)}
              ref={this.uploadInputRef}
            />
            <Button
              content="アップロード"
              onClick={() => this.uploadInputRef.current!.click()}
            />
            <Button content="追加する" onClick={() => this.returnSelection()} />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default ImageManager;
