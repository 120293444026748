import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form } from 'semantic-ui-react';
import PageWrapper from '../components/PageWrapper';
import { firebaseAuth } from '../firebase/index';

const Login: React.FC = () => {
  const history = useHistory();

  const [email, setEmail] = useState('xxx');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        history.push('/');
      }
    });
  }, [history]);

  const login = () => {
    firebaseAuth.signInWithEmailAndPassword(email, password).catch((error) => {
      setErrorMessage(error.message);
    });
  };

  const changeEmail = (e: any) => {
    const value = e.target.value;
    setEmail(value);
  };

  const changePassword = (e: any) => {
    const value = e.target.value;
    setPassword(value);
  };

  return (
    <PageWrapper>
      <Form>
        <Form.Input placeholder="E-mail address" onChange={changeEmail} />
        <Form.Input
          placeholder="Password"
          type="password"
          onChange={changePassword}
        />
        <Button
          basic={true}
          fluid={true}
          color="blue"
          content="Login"
          onClick={login}
        />
        <p>{errorMessage}</p>
      </Form>
    </PageWrapper>
  );
};

export default Login;
