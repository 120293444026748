import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { firebaseAuth, fireStore } from '../firebase';

const TechBlogDelete: React.FC = () => {
  const history = useHistory();
  const urlParams = useParams<{ id: string }>();

  useEffect(() => {
    const deletePost = async () => {
      const id = urlParams.id;

      const mySnapshot = await fireStore.collection('techblogs').doc(id).get();
      const myCategories = mySnapshot.data()?.categories;

      const categoryMapSnapshot = await fireStore
        .collection('categories')
        .doc('techblogs')
        .get();
      const allCategoryMap = categoryMapSnapshot.data()?.map;

      for (const category of myCategories) {
        allCategoryMap[category]--;
        if (allCategoryMap[category] === 0) {
          delete allCategoryMap[category];
        }
      }

      await fireStore
        .collection('categories')
        .doc('techblogs')
        .update({ map: allCategoryMap });

      await fireStore.collection('techblogs').doc(id).delete();

      await fireStore.collection('sitemap').doc(`techblog:${id}`).delete();
      history.push('/techblog');
    };

    firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        deletePost();
      } else {
        history.push('/techblog');
      }
    });
  }, [history, urlParams]);

  return null;
};

export default TechBlogDelete;
