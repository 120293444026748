import React from 'react';
import { Divider, Header, List } from 'semantic-ui-react';
import PageWrapper from '../components/PageWrapper';

const About: React.FC = () => {
  return (
    <PageWrapper active="about">
      <Header size="huge" content="About Me" textAlign="center" />
      <Divider section={true} horizontal={true}>
        <Header size="large" content="History" />
      </Divider>
      <p>
        高校2年でC#とXNA Game
        Libraryによるゲームプログラミングに惹かれ，簡単なシューティングやパズルゲームを開発．
      </p>
      <p>
        大学入学後はUnity
        C#でカジュアルゲームや対戦ゲームを中心に10本以上のゲームを手掛け，大学祭や世界的なゲームイベントでの出展の機会に恵まれる．
      </p>
      <p>
        また，大学4年にしてWeb開発・アプリ開発のアルバイトを始め，React，Flutter，Firebaseといったモダンなライブラリおよびサービスを用いたプロダクト開発を経験．
      </p>
      <p>
        同時にアルゴリズム・データ構造への理解を深めるべく，プログラミングコンテストへの参加も始めた．
      </p>
      <p>
        その他，大学では自動運転におけるコンピュータビジョンに関する研究も始め，今に至る．
      </p>
      <Divider horizontal={true}>
        <Header size="large" content="Skill" />
      </Divider>
      <List bulleted={true} style={{ padding: '1em 2em' }}>
        <List.Item>Unity(C#) (2015-)</List.Item>
        <List.Item>Android(Java) (sometimes)</List.Item>
        <List.Item>React(JavaScript) (2018-)</List.Item>
        <List.Item>Flutter(Dart) (2019-)</List.Item>
        <List.Item>ROS(C++, Python) (2018-)</List.Item>
        <List.Item>Git (2016-)</List.Item>
        <List.Item
          as="a"
          target="_blank"
          href="https://atcoder.jp/users/MiuraMiuMiu"
        >
          AtCoder (2018-)
        </List.Item>
        <List.Item
          as="a"
          target="_blank"
          href="https://codeforces.com/profile/MiuraMiuMiu"
        >
          CodeForces (2018-)
        </List.Item>
      </List>
      <Divider horizontal={true}>
        <Header size="large" content="Vision" />
      </Divider>
      <p>誰もが共通して抱く，日常や社会生活における，未解決のストレス．</p>
      <p>
        全世界なんて大げさなことではなく，自分と自分の周囲の人の，そういったストレスを少しでも軽減したい．
      </p>
      <p>そのためのモノを，作り続けたいと考えています．</p>
    </PageWrapper>
  );
};
export default About;
