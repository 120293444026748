import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardContent,
  Divider,
  Dropdown,
  Grid,
  GridColumn,
  GridRow,
  Header,
  Icon,
  Label,
  Statistic,
  StatisticLabel,
  StatisticValue,
} from 'semantic-ui-react';

interface Props {
  postTypeName: string;
  posts: any[];
  categoryOptions: any[];
}

const PostList: React.FC<Props> = ({
  postTypeName,
  posts,
  categoryOptions,
}) => {
  const allCategory = 'すべて';
  if (categoryOptions[0].value !== allCategory) {
    categoryOptions.unshift({
      key: allCategory,
      text: allCategory,
      value: allCategory,
    });
  }
  const [selectedCategory, setSelectedCategory] = useState(allCategory);

  const onCategoryChanged = useCallback((e, { value }) => {
    setSelectedCategory(value);
  }, []);

  const getDateString = (d: Date) => {
    const year = ('000' + d.getFullYear()).slice(-4);
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const day = ('0' + d.getDate()).slice(-2);
    return `${year}/${month}/${day}`;
  };

  return (
    <>
      <Divider section={true} horizontal={true}>
        <Header size="large" content="Category" />
      </Divider>
      <Dropdown
        fluid={true}
        selection={true}
        options={categoryOptions}
        onChange={onCategoryChanged}
        value={selectedCategory}
      />
      <Divider section={true} horizontal={true}>
        <Header size="large" content="Posts" />
      </Divider>
      {posts.map((post, index) => {
        const content = post.data;
        if (
          selectedCategory !== allCategory &&
          !content.categories.includes(selectedCategory)
        ) {
          return null;
        }

        let iconName = null;
        if (content.accessibility === 0) {
          iconName = 'edit';
        } else if (content.accessibility === 1) {
          iconName = 'key';
        }

        return (
          <Card
            fluid={true}
            key={index}
            as={Link}
            to={`${postTypeName}/${post.id}`}
          >
            <CardContent>
              <Grid doubling={true} columns={2}>
                <GridRow>
                  <GridColumn width={4}>
                    <Statistic size="mini">
                      <StatisticValue>
                        {getDateString(content.date.toDate())}
                      </StatisticValue>
                      {!!content.updateDate ? (
                        <StatisticLabel>
                          <Icon name="history" />
                          {getDateString(content.updateDate.toDate())}
                        </StatisticLabel>
                      ) : null}
                    </Statistic>
                  </GridColumn>
                  <GridColumn width={12}>
                    <Header
                      icon={iconName}
                      size="medium"
                      content={content.title}
                    />
                    <p style={{ color: '#000' }}>{content.description}</p>
                  </GridColumn>
                </GridRow>
              </Grid>
            </CardContent>
            <CardContent extra={true}>
              {content.categories.map((category: any, categoryIndex: any) => {
                return <Label key={categoryIndex}>{category}</Label>;
              })}
            </CardContent>
          </Card>
        );
      })}
    </>
  );
};

export default PostList;
