import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Header, Loader } from 'semantic-ui-react';

import PageWrapper from '../components/PageWrapper';
import PostList from '../components/PostList';
import { firebaseAuth, fireStore } from '../firebase';

const DiaryHome: React.FC = () => {
  const [authed, setAuthed] = useState(false);
  const [posts, setPosts] = useState([] as any[]);
  const [categoryOptions, setCategoryOptions] = useState([] as any);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    fireStore
      .collection('categories')
      .doc('diary')
      .get()
      .then((documentSnapshot) => {
        const map = documentSnapshot.data()?.map || {};
        const newCategoryOptions = [];
        for (const category of Object.keys(map)) {
          newCategoryOptions.push({
            key: category,
            text: category,
            value: category,
          });
        }
        setCategoryOptions(newCategoryOptions);
      });

    firebaseAuth.onAuthStateChanged(
      async (user) => {
        let querySnapshot;
        if (user === null) {
          querySnapshot = await fireStore
            .collection('diary')
            .where('accessibility', '==', 2)
            .orderBy('date', 'desc')
            .get();
        } else {
          querySnapshot = await fireStore
            .collection('diary')
            .orderBy('date', 'desc')
            .get();
        }

        const newPosts: any[] = [];
        querySnapshot.forEach((doc) => {
          newPosts.push({ id: doc.id, data: doc.data() });
        });
        setAuthed(user !== null);
        setPosts(newPosts);
        setLoaded(true);
      },
      () => {
        setLoaded(true);
      },
      () => {
        setLoaded(true);
      }
    );
  }, []);

  return (
    <PageWrapper active="diary">
      <Header size="huge" content="Diary" textAlign="center" />
      {(() => {
        if (authed) {
          return (
            <Button
              basic={true}
              color="blue"
              content="新規作成"
              as={Link}
              to="diary/new/edit"
            />
          );
        }
      })()}
      {(() => {
        if (loaded) {
          return (
            <PostList
              postTypeName="diary"
              posts={posts}
              categoryOptions={categoryOptions}
            />
          );
        } else {
          return (
            <Loader active={true} inline="centered">
              Loading
            </Loader>
          );
        }
      })()}
    </PageWrapper>
  );
};

export default DiaryHome;
