import React from 'react';
import { Header, Segment } from 'semantic-ui-react';

import AppBar from '../components/AppBar';
import Footer from '../components/Footer';

const Home: React.FC = () => {
  return (
    <div
      style={{
        position: 'relative',
        height: '100vh',
      }}
    >
      <AppBar active="home" />
      <Segment
        placeholder={true}
        vertical={true}
        textAlign="center"
        style={{
          position: 'absolute',
          top: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: '#F5F5F5',
        }}
      >
        <Header style={{ fontSize: '4em' }}>
          <span style={{ color: '#0A0905' }}>sono</span>
          <span style={{ color: '#EA0437' }}>'</span>
          <span style={{ color: '#0A0905' }}>s portal</span>
        </Header>
        <p style={{ fontSize: '1em', fontWeight: 'bold' }}>
          Besides being an engineer,
        </p>
        <p style={{ fontSize: '1em', fontWeight: 'bold' }}>
          I'm an amateur programmer.
        </p>
      </Segment>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100vw',
        }}
      >
        <Footer />
      </div>
    </div>
  );
};

export default Home;
