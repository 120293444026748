import * as React from 'react';
import {
  Divider,
  Header,
  Icon,
  Label,
  Statistic,
  StatisticLabel,
  StatisticValue,
} from 'semantic-ui-react';
import Markdown from './Markdown';

interface Props {
  accessibility: number;
  title: string;
  content: string;
  date: any;
  updateDate: any;
  categories: string[];
}

const PostShow: React.FC<Props> = ({
  accessibility,
  title,
  content,
  date,
  updateDate,
  categories,
}) => {
  let iconName = null;
  if (accessibility === 0) {
    iconName = 'edit';
  } else if (accessibility === 1) {
    iconName = 'key';
  }

  const getDateString = (d: Date) => {
    const year = ('000' + d.getFullYear()).slice(-4);
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const day = ('0' + d.getDate()).slice(-2);
    return `${year}/${month}/${day}`;
  };

  return (
    <>
      <Statistic size="mini" horizontal={true}>
        <StatisticValue>{getDateString(date.toDate())}</StatisticValue>
        {!!updateDate ? (
          <StatisticLabel>
            <Icon name="history" />
            {getDateString(updateDate.toDate())}
          </StatisticLabel>
        ) : null}
      </Statistic>
      <Header icon={iconName} size="large" content={title} />
      {categories.map((category, index) => {
        return <Label key={index}>{category}</Label>;
      })}
      <Divider section={true} />
      <Markdown>{content}</Markdown>
    </>
  );
};

export default PostShow;
