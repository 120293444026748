import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
import createBrowserHistory from 'history/createBrowserHistory';
import ScrollToTop from './components/ScrollToTop';
import About from './pages/About';
import Contact from './pages/Contact';
import DiaryDelete from './pages/DiaryDelete';
import DiaryEdit from './pages/DiaryEdit';
import DiaryHome from './pages/DiaryHome';
import DiaryShow from './pages/DiaryShow';
import Home from './pages/Home';
import Login from './pages/Login';
import Logout from './pages/Logout';
import ProductHome from './pages/ProductHome';
import TechBlogDelete from './pages/TechBlogDelete';
import TechBlogEdit from './pages/TechBlogEdit';
import TechBlogHome from './pages/TechBlogHome';
import TechBlogShow from './pages/TechBlogShow';

const App: React.FC = () => {
  ReactGA.initialize('UA-146100602-1');
  const history = createBrowserHistory();
  history.listen(({ pathname }) => {
    ReactGA.set({ page: pathname });
    ReactGA.pageview(pathname);
  });

  return (
    <Router history={history}>
      <ScrollToTop>
        <Switch>
          <Route exact={true} path="/about" component={About} />
          <Route exact={true} path="/product" component={ProductHome} />
          <Route exact={true} path="/contact" component={Contact} />
          <Route exact={true} path="/techblog" component={TechBlogHome} />
          <Route exact={true} path="/techblog/:id" component={TechBlogShow} />
          <Route
            exact={true}
            path="/techblog/:id/edit"
            component={TechBlogEdit}
          />
          <Route
            exact={true}
            path="/techblog/:id/delete"
            component={TechBlogDelete}
          />
          <Route exact={true} path="/diary" component={DiaryHome} />
          <Route exact={true} path="/diary/:id" component={DiaryShow} />
          <Route exact={true} path="/diary/:id/edit" component={DiaryEdit} />
          <Route
            exact={true}
            path="/diary/:id/delete"
            component={DiaryDelete}
          />
          <Route exact={true} path="/login" component={Login} />
          <Route exact={true} path="/logout" component={Logout} />
          <Route path="/" component={Home} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default App;
