import 'katex/dist/katex.min.css';
import ReactMarkdown, { compiler, MarkdownOptions } from 'markdown-to-jsx';
import * as React from 'react';
import Highlight from 'react-highlight';
import { Header, List } from 'semantic-ui-react';
// tslint:disable-next-line:no-var-requires
const ReactKatex = require('react-katex');

interface IReactKatex {
  InlineMath: React.FC;
  BlockMath: React.FC;
}

const options: MarkdownOptions = {
  overrides: {
    h1: {
      component: (props: any) => (
        <Header
          size="large"
          {...props}
          style={{
            padding: '0.2em 0.2em',
            borderTop: 'solid 1px #AAAAAA',
            borderBottom: 'solid 1px #AAAAAA',
          }}
        />
      ),
    },
    h2: {
      component: (props: any) => (
        <Header
          size="medium"
          {...props}
          style={{
            padding: '0.1em 0.25em',
            borderLeft: 'solid 3px #AAAAAA',
            borderBottom: 'solid 1px #DDDDDD',
          }}
        />
      ),
    },
    h3: {
      component: (props: any) => (
        <Header
          size="small"
          {...props}
          style={{
            borderBottom: 'solid 1px #DDDDDD',
          }}
        />
      ),
    },
    h4: {
      component: (props: any) => (
        <Header
          size="tiny"
          paragraph={true}
          {...props}
          style={{
            borderBottom: 'dashed 1px #DDDDDD',
          }}
        />
      ),
    },
    ul: { component: (props: any) => <List bulleted={true} {...props} /> },
    ol: { component: (props: any) => <List ordered={true} {...props} /> },
    li: { component: (props: any) => <List.Item {...props} /> },
    code: {
      component: (props: any) => (
        <div
          style={{
            border: 'dashed 2px #DDDDDD',
          }}
        >
          <Highlight className={props.className} {...props} />
        </div>
      ),
    },
    img: {
      component: (props: any) => (
        <img alt="" {...props} style={{ maxWidth: '100%' }} />
      ),
    },
    InlineMath: { component: ReactKatex.InlineMath },
    BlockMath: { component: ReactKatex.BlockMath },
  },
};

export default function Markdown(props: any) {
  return <ReactMarkdown options={options} {...props} />;
}

export function Compiled(content: string) {
  return compiler(content, options);
}
