import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Divider, Segment } from 'semantic-ui-react';

const Footer: React.FC = () => {
  return (
    <Segment color="red" style={{ padding: '2em 0em' }}>
      <Container textAlign="center">
        <Button color="black" basic={true} as={Link} to="/contact">
          Contact Me
        </Button>
        <Divider />
        <p>Copyright © 2019 sono. All rights reserved.</p>
      </Container>
    </Segment>
  );
};

export default Footer;
