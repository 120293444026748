import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Divider,
  Dropdown,
  Form,
  Header,
  Modal,
} from 'semantic-ui-react';

import ImageManager from '../components/ImageManager';
import PageWrapper from '../components/PageWrapper';
import { firebaseAuth, fireStore, functions } from '../firebase';

import Markdown, { Compiled } from '../components/Markdown';
import innerText from '../utils/innerText';

interface Post {
  accessibility: number;
  title: string;
  content: string;
  description: string;
  date: Date;
  updateDate: Date;
  categories: string[];
}

const DiaryEdit: React.FC = () => {
  const history = useHistory();
  const urlParams = useParams<{ id: string }>();

  const [post, setPost] = useState<Post>({
    accessibility: 0,
    title: '',
    content: '',
    description: '',
    date: new Date(),
    updateDate: new Date(),
    categories: [],
  });
  const [prevCategories, setPrevCategories] = useState<string[]>([]);
  const [onPreview, setOnPreview] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
  const [notifyOrNot, setNotifyOrNot] = useState<boolean>(false);
  const [prevAccessibility, setPrevAccessibility] = useState(0);

  useEffect(() => {
    firebaseAuth.onAuthStateChanged((user) => {
      if (!user) {
        history.push('/diary');
      }
    });
  }, [history]);

  useEffect(() => {
    if (urlParams.id !== 'new') {
      fireStore
        .collection('diary')
        .doc(urlParams.id)
        .get()
        .then((documentSnapshot) => {
          const data = documentSnapshot.data() as Post;
          if (data) {
            setPost(data);
            setPrevCategories(data?.categories);
            setPrevAccessibility(data?.accessibility);
          }
        });
    }
  }, [urlParams.id]);

  useEffect(() => {
    fireStore
      .collection('categories')
      .doc('diary')
      .get()
      .then((documentSnapshot) => {
        const map = documentSnapshot.data()?.map as { [key: string]: number };
        const categories = [];
        for (const name of Object.keys(map)) {
          categories.push({ key: name, text: name, value: name });
        }
        setCategoryOptions(categories);
      });
  }, []);

  const addCategory = (value: string) => {
    setCategoryOptions([
      ...categoryOptions,
      { key: value, text: value, value },
    ]);
  };

  const addImageLinks = (urls: string[]) => {
    let content = post.content;
    for (const url of urls) {
      content += `\n![image](${url})`;
    }
    setPost({ ...post, content });
  };

  const savePost = async () => {
    const newPost: Post = {
      accessibility: post.accessibility,
      title: post.title,
      content: post.content,
      description: post.description,
      date: post.date,
      updateDate: new Date(),
      categories: post.categories,
    };
    if (post.description.trim() === '') {
      newPost.description = innerText(Compiled(post.content)).slice(0, 100);
    }
    if (post.accessibility > prevAccessibility) {
      newPost.date = new Date();
    }

    // remove and add category
    fireStore
      .collection('categories')
      .doc('diary')
      .get()
      .then((documentSnapshot) => {
        const map = documentSnapshot.data()?.map;

        for (const category of prevCategories) {
          map[category]--;
          if (map[category] === 0) {
            delete map[category];
          }
        }

        for (const category of post.categories) {
          if (category in map) {
            map[category]++;
          } else {
            map[category] = 1;
          }
        }

        fireStore.collection('categories').doc('diary').update({ map });
      });

    let id = urlParams.id;
    if (id === 'new') {
      const docRef = await fireStore.collection('diary').add(newPost);
      id = docRef.id;
    } else {
      await fireStore.collection('diary').doc(id).set(newPost);
    }

    if (post.accessibility > 0) {
      const sitemap = {
        title: post.title,
        description: post.description,
        'og:description': post.description,
        date: post.date,
        ignore: post.accessibility === 1,
      };
      await fireStore.collection('sitemap').doc(`diary:${id}`).set(sitemap);
    }

    if (notifyOrNot) {
      const updateNotifier = functions.httpsCallable('updateNotifier');
      updateNotifier({ pageRoute: `diary/${id}` });
    }

    history.push(`/diary/${id}`);
  };

  const switchPreviewModal = (onOff: boolean) => {
    setOnPreview(onOff);
  };

  return (
    <PageWrapper active="diary">
      <Form>
        <Dropdown
          placeholder="公開設定"
          fluid={true}
          selection={true}
          value={post.accessibility}
          onChange={(e, data) =>
            setPost({ ...post, accessibility: data.value as number })
          }
          options={[
            { key: 0, text: '下書き', value: 0 },
            { key: 1, text: '限定公開', value: 1 },
            { key: 2, text: '公開', value: 2 },
          ]}
        />

        <Form.Input
          label="タイトル"
          value={post.title}
          onChange={(e) => setPost({ ...post, title: e.target.value })}
        />

        <Dropdown
          options={categoryOptions}
          placeholder="カテゴリー"
          search={true}
          selection={true}
          fluid={true}
          multiple={true}
          allowAdditions={true}
          value={post.categories}
          onAddItem={(e, { value }) => addCategory(value as string)}
          onChange={(e, { value }) =>
            setPost({ ...post, categories: value as string[] })
          }
        />

        <Form.TextArea
          rows={10}
          autoHeight={true}
          label="本文(マークダウン対応)"
          value={post.content}
          onChange={(e) => setPost({ ...post, content: e.currentTarget.value })}
        />

        <ImageManager onCloseModal={(urls) => addImageLinks(urls)} />

        <Form.Input
          label="紹介文(50文字まで)"
          value={post.description}
          onChange={(e) => setPost({ ...post, description: e.target.value })}
        />

        <Form.Checkbox
          label="変更をTwitterに通知する"
          disabled={post.accessibility !== 2}
          onChange={() => setNotifyOrNot(!notifyOrNot)}
          checked={notifyOrNot}
        />
      </Form>

      <Divider />

      <Button
        content="プレビューを見る"
        onClick={() => switchPreviewModal(true)}
      />

      <Modal open={onPreview} onClose={() => switchPreviewModal(false)}>
        <Header content={post.title} />
        <Modal.Content scrolling={true}>
          <Markdown>{post.content}</Markdown>
        </Modal.Content>
        <Modal.Actions>
          <Button content="閉じる" onClick={() => switchPreviewModal(false)} />
        </Modal.Actions>
      </Modal>
      <Button content="保存" onClick={() => savePost()} />
    </PageWrapper>
  );
};

export default DiaryEdit;
